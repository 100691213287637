<template>
    <div>
        <b-card>
            <app-view-table :title="trans('settings-changelog',167)" :isTitleTotal="true" :fields="fields" :items.sync="items" :filters="filters" :isMenuColumn="programmers_list.includes(user_id)" @onRowClicked="item=>$set(item, '_showDetails', !item._showDetails)" :loading="loading_list">
                
                <template v-slot:buttons v-if="programmers_list.includes(user_id)">
                    <app-button @click="add" type="primary">{{trans('add',175)}}</app-button>
                </template>

                <template v-slot:row-details="row" >
                    <settings-change-log-details-component :itemData="row.data.item" />
                </template>

                <template v-slot:menu-items="cell">
                     <b-dropdown-item @click="changeItem(cell.data.item)">{{trans('change',175)}}</b-dropdown-item> 
                     <b-dropdown-item @click="deleteItem(cell.data.item.id)">{{trans('remove',175)}}</b-dropdown-item>
                </template> 

            </app-view-table>
        </b-card>
        <settings-change-log-add v-if="mAdd.show" :show.sync="mAdd.show" :args.sync="mAdd.args" :result.sync="mAdd.result" :return.sync="items" />
        <settings-change-log-change v-if="mChange.show" :show.sync="mChange.show" :args.sync="mChange.args" :result.sync="mChange.result" :return.sync="items" />
    </div>
</template>
<script>
import axios from "@axios";
import SettingsChangeLogDetailsComponent from './SettingsChangeLogDetailsComponent.vue';
import SettingsChangeLogAdd from './SettingsChangeLogAdd.vue';
import SettingsChangeLogChange from './SettingsChangeLogChange.vue';
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    
    components: {
        SettingsChangeLogDetailsComponent,
        SettingsChangeLogAdd,
        SettingsChangeLogChange
    },

    props: {

    },

    mounted() {
        this.onCreateThis();
    },

    created() {
    },

    data()  {
        return{
            user_id: JSON.parse(localStorage.getItem('user')).id,
            programmers_list: [2, 4, 6, 147, 150, 151, 154, 155, 160],
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 20,
                search: "",
                list_type: 1,
                changed: 0,
            },
            fields:[
                { key: "version", label: this.trans('settings-version',167), visible: true, thStyle: { "min-width": "150px", "max-width": "150px" } },
                { key: "entry_date", label: this.trans('entry-date',182), visible: true, thStyle: { "min-width": "150px", "max-width": "150px" } },
                { key: "user_name", label: this.trans('settings-user-name',167), visible: true, thStyle: { "min-width": "150px", "max-width": "150px" } },
            ],
            items: [],
            mAdd: { show: false, args:{ filters: null}, result: 0, return: null },
            mChange: { show: false, args:{
                id_change_log_data_main: null,
                version: null,
                entry_date: null,
                entry_time: null,
                description: null,
                filters: null
                }, result: 0, return: null },
            alertClass: new AppAlerts(),
        }
    },

    methods: {

        onCreateThis() {
            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
        },

        getList(){
           this.loading_list = true;

           axios
                .post("settings/changeLog/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {                  
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        add(){
            this.mAdd.args.filters = this.filters;
            this.mAdd.show = true;
        },

        changeItem(item){
            this.mChange.args.id_change_log_data_main = item.id;
            this.mChange.args.version = item.version;
            this.mChange.args.entry_date = item.entry_date.split(' ')[0];
            this.mChange.args.entry_time = item.entry_date.split(' ')[1];
            this.mChange.args.description = item.description;
            this.mChange.args.filters = this.filters;
            this.mChange.show = true;
        },

        deleteItem(id_change_log_data_main){

            this.alertClass.openAlertConfirmation(this.trans('settings-continue-confirmation',167)).then(res => {
                if(res == true){

                this.loading_list = true;

                axios
                    .post("settings/changeLog/delete", {
                        filters: JSON.stringify(this.filters),
                        id_change_log_data_main: id_change_log_data_main
                    })
                    .then((res) => {                  
                        this.filters.page = res.data.page;
                        this.items = res.data.items;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });

                }
            });

        }
          
    },
    
}
</script>
<style>
.app-local-max-width{
    max-width: 20px;
}
</style>