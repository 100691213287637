<template>
    <b-modal ref="modal-add" v-model="computedShow" no-close-on-backdrop :title="trans('settings-change-changelog',167)">

        <ValidationObserver ref="form" >

            <app-row-left-label :label="trans('settings-version',167)">
                <app-input v-model="cArgs.version" validatorRules="required" validatorName="Versie" />
            </app-row-left-label>

            <app-row-left-label :label="trans('entry-date',182)" :slots="[6,6]" :customError="true">
                <template v-slot:col-1>
                    <app-date v-model="cArgs.entry_date" @input="validateEntryDate" />
                </template>

                <template v-slot:col-2>
                    <app-time v-model="cArgs.entry_time" @input="validateEntryDate" />
                </template>

                <template #custom-error>
                    <small class="text-danger" v-if="entry_date_error">Ingangsdatum is verplicht</small>
                </template>

            </app-row-left-label>

            <app-row-left-label :label="trans('settings-release-update-description',167)">
                <app-memo v-model="cArgs.description" :maxLength="1000"/>
            </app-row-left-label>

        </ValidationObserver>



        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="add" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>
<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {

    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data(){
        return {
            loading: false,
            entry_date_error: false
        }
    },

    methods: {

        add(){
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    this.validateEntryDate();
                    return false;
                }if(!this.validateEntryDate()){
                    return false;
                } else{
                    this.loading = true;
                    axios
                        .post('/settings/changeLog/change', {
                            id_change_log_data_main: this.cArgs.id_change_log_data_main,
                            version: this.cArgs.version,
                            entry_date: this.cArgs.entry_date + ' ' + this.cArgs.entry_time,
                            description: this.cArgs.description,
                            filters: JSON.stringify(this.args.filters),
                        })
                        .then((res) => {
                            this.cReturn = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                            this.computedShow = false;
                        })
                    

                }
            });

        },

        validateEntryDate(event){
            if(this.cArgs.entry_date == null || this.cArgs.entry_time == null){
                this.entry_date_error = true;
                return false;
            }
            else if(this.cArgs.entry_date && this.cArgs.entry_time) {
                this.entry_date_error = false;
                return true;
            }

        },

        closeModal() {
            this.computedShow = false;
        },
    }
    
}
</script>
<style>

</style>