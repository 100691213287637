<template>
    <div class="mx-4 my-4">

        
        <h4 class="mb-3">{{trans('settings-release-update-description',167)}}</h4>
        <b-row>
            <b-col start>
                <b-alert variant="light" show class="p-3 mb-2 app-local-height align-items-center d-block h-100">
                    <div class="text-small"><pre class="app-local-pre">{{itemData.description}}</pre></div>    
                </b-alert> 
            </b-col>
        </b-row>
    </div>
</template>

<script>

import axios from "@axios";

export default {
    components: { 
       
    },

    data() {
        return {
           
        };
    },

    props:["itemData"],

    mounted() {
       
    },
};
</script>

<style scoped>
    .app-local-pre{
        white-space: pre-wrap;
    }
</style>
